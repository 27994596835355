@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=VT323&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* .news-div {
  width: 300px;
  display: flex;
  margin: 0 1rem;
} */

/* 
.news-div img {
  width: 300px;
}

.news-box {
  width: 300px;
} */

.blogs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  padding: 40px 0;
}

.blog {
  min-width: 300px;
  margin: auto;
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  height: 100%;
  text-decoration: none;
  color: black;
}

.blog > div {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0px 30px 0px 30px;
}

.blog img {
  width: 100% !important;
}

.sourceName > span {
  display: inline;
  background: rgba(38, 0, 128, 0.226);
  font-size: 14px;
  padding: 5px 6px;
  margin: 0 0.5rem;
  color: rgba(0, 11, 43, 0.534);
  border-radius: 3px;
}

.sourceName > p {
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
}

.sourceName {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.blog > div > h1 {
  font-size: 25px;
  letter-spacing: -0.6px;
  line-height: 1.3em;
  padding: 10px 0;
  font-weight: 700;
  text-decoration: underline;
}

.blog > div > p {
  font-size: 13px;
  line-height: 1.6em;
  color: rgb(90, 90, 90);
  padding-bottom: 30px;
}
